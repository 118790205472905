<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="error-template">
          <h1>{{ $t("error404.oops") }}</h1>
          <h2>{{ $t("error404.404_not_found") }}</h2>
          <div class="error-details">
            {{ $t("error404.an_error_occured") }}
          </div>
          <div class="error-actions">
            <router-link to="/">{{ $t("error404.main_page") }}</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "Error404",
  setup() {},
});
</script>
<style scoped>
.error-template {
  padding: 40px 15px;
  text-align: center;
}
.error-actions {
  margin-top: 15px;
  margin-bottom: 15px;
}
.error-actions .btn {
  margin-right: 10px;
}
</style>
